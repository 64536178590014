@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  background-color: "#1d2939";
  box-sizing: border-box;
}

html,
body {
  font-family: sans-serif;
  background-color: #1d2939;
  margin: 0;
  padding: 0;
  height: 100vh;
  /* overflow: hidden; */
  overscroll-behavior: none; /* Prevent bounce or stretching */
}

#root {
  height: 100%;
}
